import React, { useEffect, useContext, useState, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import UserContext from '../../../contexts/userContext';

import './RewardData.css';
import { callApi } from '../../../api/API';
import { _empty } from '../../../utils/utils';
import RewardForm from '../reward-form/RewardForm';

const RewardData = ({ currentPage, pageSize }) => {
  const { userData } = useContext(UserContext);

  const [isRewardOpen, setIsRewardOpen] = useState(isRewardOpen);
  const [rewards, setRewards] = useState([]);

  // fetching the get api to display the rewards we created on the basis of business email
  const getAllRewards = async (payload) => {
    try {
      const currentTimeStamp = new Date().getTime();
      const getReward = await callApi(
        `/rewards/all?id=${userData?.id}&fetchTime=${currentTimeStamp}`,
        'GET',
        payload,
        'Bearer ' + userData?.token,
      );
      if (getReward?.status === 404) {
        setRewards([]);
        return;
      }
      setRewards(getReward);
    } catch (err) {
      console.log('err', err);
    }
  };

  const rewardTypeFromApi = useMemo(() => {
    if (!rewards?.length) return [];
    return rewards.map((el) => el?.rewardType)?.filter((el) => el);
  }, [rewards]);

  useEffect(() => {
    if (userData.uname) {
      getAllRewards();
    }
  }, [userData.uname]);

  return (
    <React.Fragment>
      <header className='d-flex justify-content-between justify-content-between secttion-container__header position-relative p-0'>
        {isRewardOpen && (
          <RewardForm
            getAllRewardsCb={getAllRewards}
            setIsRewardOpen={setIsRewardOpen}
            businessRewardType={rewardTypeFromApi}
          />
        )}
      </header>

      {rewards?.length ? (
        <>
          <h4 style={{ fontSize: '12px', padding: '20px' }}>
            Earned reward will expire in 30 days if not redeemed
          </h4>

          <div className='table-container flex-grow-1 position-relative'>
            <table className='business-table border-0 m-0' style={{ width: '100%' }}>
              <tbody>
                <tr className='table-row-container'>
                  <th className='table-heading'>Title</th>
                  <th className='text-center table-heading'>Description</th>
                  <th className='text-center table-heading'>Status</th>
                  <th className='text-center table-heading'>Interval (in hrs.)</th>
                  <th className='text-center table-heading'>No. of transactions</th>
                  <th className='text-center table-heading'>Min billing amouunt</th>
                  <th className='text-center table-heading'>Discount %age</th>
                  <th className='text-center table-heading'>Active days</th>
                  <th className='text-center table-heading'>Start time</th>
                  <th className='text-center table-heading'>End time</th>
                  <th className='text-center table-heading'>Timezone</th>
                  <th className='text-center table-heading'>Max discount amount</th>
                  <th className='text-center table-heading'>Reward type</th>
                  <th className='text-center table-heading'>Bonus</th>
                </tr>
                {rewards &&
                  rewards.slice((currentPage - 1) * pageSize, pageSize * currentPage).map((el) => (
                    <tr>
                      <td className='align-middle text-black'>{el?.title ? el?.title : 'N/A'}</td>
                      <td className='align-middle text-center  table-black'>
                        {el?.description ? el?.description : 'N/A'}
                      </td>
                      <td className='align-middle  text-center text-black'>
                        {el?.status ? el?.status : 'N/A'}
                      </td>
                      <td className='align-middle text-center text-black'>
                        {el?.interval ? el?.interval : 'N/A'}
                      </td>
                      <td className='align-middle text-center text-black'>
                        {el?.visits ? el?.visits : 'N/A'}
                      </td>
                      <td className='align-middle text-center text-black'>
                        {el?.minimumBillingAmount ? el?.minimumBillingAmount : 'N/A'}
                      </td>
                      <td className='align-middle text-center text-black'>
                        {el?.discountPercentage ? el?.discountPercentage : 'N/A'}
                      </td>
                      <td className='align-middle text-center text-black'>
                        {el?.activeDays?.length
                          ? el.activeDays
                              .map((day) => day.charAt(0).toUpperCase() + day.slice(1))
                              .join(', ')
                          : 'N/A'}
                      </td>
                      <td className='align-middle text-center text-black'>
                        {el?.startTime ? el?.startTime : 'N/A'}
                      </td>
                      <td className='align-middle text-center text-black'>
                        {el?.endTime ? el?.endTime : 'N/A'}
                      </td>
                      <td className='align-middle text-center text-black'>
                        {el?.timeZone ? el?.timeZone : 'N/A'}
                      </td>
                      <td className='align-middle text-center text-black'>
                        {el?.maxDiscountAmount ? el?.maxDiscountAmount : 'N/A'}
                      </td>
                      <td className='align-middle text-center text-black'>
                        {el?.rewardType ? el?.rewardType : 'N/A'}
                      </td>
                      <td className='align-middle text-center text-black'>
                        {el?.bonus ? el?.bonus : 'N/A'}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        ''
      )}

      {rewards?.length < 2 && (
        <div className='text-center reward-btn'>
          <p>You have not created any reward yet</p>
          <Button
            onClick={() => {
              setIsRewardOpen(!isRewardOpen);
            }}
          >
            Create reward
          </Button>
        </div>
      )}
    </React.Fragment>
  );
};

export default RewardData;
